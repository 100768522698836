<template>
  <CRow>
    <CCol md="12">
      <CCard class="clapans">
        <CCardHeader>
          <div class="d-flex aligin-items-center justify-content-between">
            <span>Мастеры</span>
            <CButton color="success" @click="warningModal = !warningModal">
              Добавить
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          <div class="d-flex aligin-items-center justify-content-between">
            <CSelect
                v-if="isSelect"
                horizontal
                label="Действие на выбор"
                :value.sync="action_switcher"
                :options="['Не выбрано ничего','Включить отмеченные','Отключить отмеченные']"
                v-on:change="actionSwitcher()"
                class="col-4"
            />
            <CSelect
                horizontal
                label="Город"
                :options="['Алматы', 'Астана']"
                :value.sync="action_switcher_city"
                v-on:change="actionSwitcherCity()"
                class="col-4"
            />
          </div>
          <CDataTable
              class="mb-0 table-outline"
              hover
              :items="tableItems"
              :fields="tableFields"
              head-color="light"
              column-filter
              table-filter
              sorter
              :tableFilter="{'label':'Поиск','placeholder':'Введите слово для поиска'}"
          >
            <td slot="select" slot-scope="{ item }">
              <CInputCheckbox
                  :value="item.select"
                  custom
                  inline
                  @change="actionSelectChange(item)"
              />
            </td>
            <td slot="city" slot-scope="{ item }">
              {{ item.city }}
            </td>
            <td slot="district" slot-scope="{ item }">
              {{ item.district }}
            </td>
            <td slot="street" slot-scope="{ item }">
              {{ item.street }}
            </td>
            <td slot="building" slot-scope="{ item }">
              {{ item.building }}
            </td>
            <td slot="numHouse" slot-scope="{ item }">
              {{ item.typeofbuilding }}
            </td>
            <td slot="numDoor" slot-scope="{ item }">
              {{ item.buildingnum }}
            </td>
            <td slot="numMine" slot-scope="{ item }">
              {{ item.entrancenum }}

            </td>
            <td slot="stage" slot-scope="{ item }">
              {{ item.shaftnum }}
            </td>
            <td slot="numOffice" slot-scope="{ item }">
              {{ item.floor }}
            </td>
            <td slot="id" slot-scope="{ item }">
              {{ item.masterID }}
            </td>
            <td slot="status" slot-scope="{ item }">
              <CBadge v-if="item.status==0" color="danger">
                выключен
              </CBadge
              >
              <CBadge v-else-if="item.status==1" color="success">включен</CBadge>
              <CBadge v-else-if="item.status==2" color="warning">нет данных</CBadge>
              <CBadge v-else color="info">не известно</CBadge>
              <br/>
              <CSwitch
                  class="mt-1"
                  color="primary"
                  :checked.sync="item.status==1"
                  :value="item.status"
                  @update:checked="actionStatusChange(item)"
              />
            </td>
            <td slot="settings" slot-scope="{ item, index }">
              <span
                  style="white-space: nowrap; cursor: pointer"
                  class="d-flex align-items-center"
                  @click="warningModal = !warningModal"
              ><CIcon name="cilSettings" class="mr-1"/> Настройки
              </span>
            </td>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
const qs = require('qs')
const axios = require('axios')
export default {
  name: "Master",
  data() {
    return { warning: false,
      success: false,
      alert_message: '',
      warningModal: false,
      isSelect: false,
      action_switcher: '',
      action_switcher_city: '',
      id: "",
      tableItems: [],
      tableFields: [
        {key: "select", label: "", sorter: false, filter: false},
        {key: "city", label: "Город "},
        {key: "district", label: "Район ", _classes: "text-center"},
        {key: "street", label: "Улица "},
        {key: "typeofbuilding", label: "Вид здания"},
        {key: "buildingnum", label: "Номер дома "},
        {key: "entrancenum", label: "Номер подъезда "},
        {key: "shaftnum", label: "Номер шахты "},
        {key: "floor", label: "Этаж"},
        {key: "status", label: "Статус"},
        {key: "settings", label: "Действия"},

      ],
    };
  },
  mounted() {
    this.getResults(0);
  },
  methods: {
    actionSelectChange(value) {
      let app = this;
      app.isSelect = true;
      for (var i = 0; i < app.tableItems.length; i++) {
        if (app.tableItems[i].masterID == value.masterID) {
          app.tableItems[i].select = true;
        }
      }
    },
    actionSwitcher() {
      let app = this;
      for (var i = 0; i < app.tableItems.length; i++) {
        if (app.tableItems[i].select == true) {
          if (this.action_switcher == 'Включить отмеченные') {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
            axios(
                {
                  method: 'post',
                  url: '/turnon/',
                  data: qs.stringify({
                    device_id: app.tableItems[i].masterID,
                  }),
                })
                .then(({data}) => {
                  app.success = true;
                  app.alert_message = data.responce;
                  setTimeout(function () {
                    app.success = false;
                  }, 3000)

                }).catch(function (error) {

            })
          } else if (this.action_switcher == 'Отключить отмеченные') {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
            axios(
                {
                  method: 'post',
                  url: '/turnoff/',
                  data: qs.stringify({
                    device_id: app.tableItems[i].masterID,
                  }),
                })
                .then(({data}) => {
                  app.success = true;
                  app.alert_message = data.responce;
                  setTimeout(function () {
                    app.success = false;
                  }, 3000)

                }).catch(function (error) {

            })
          } else {

          }

        }
      }
    },
    actionSwitcherCity() {
      if (this.action_switcher_city == 'Астана') {
        this.getResults(1);
      } else {
        this.getResults(0);
      }
    },
    actionStatusChange(item) {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios(
          {
            method: 'post',
            url: '/turnon/',
            data: qs.stringify({
              device_id: item.masterID,
            }),
          })
          .then(({data}) => {
            app.success = true;
            app.alert_message = data.responce;
            setTimeout(function () {
              app.success = false;
            }, 3000)

          }).catch(function (error) {

      })
    },
    getResults(city) {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios.get('/getmasterbycity/' + city).then(response => {
        app.tableItems = response.data;
        for (var i = 0; i < app.tableItems.length; i++) {
          app.tableItems[i].select = false;
        }
      }).catch(error => {
        if (error.response.status == 401) {
          window.auth.logout();
        }
      });
    },
  }
};
</script>
<style>
.clapans .form-inline {
  margin-left: 15px;
}

.alert_block {
  position: fixed;
  right: 0;
  bottom: 0;
}
</style>